package com.catbit.opinionpoll.ui.states

import com.catbit.opinionpoll.inputs.state.InputGraphUIState

data class StatsUIState(
    val totalAnswersByFormChart: InputGraphUIState,
    val totalAnswers: Int,
    val totalForms: Int,
    val totalUsers: Int,
)
