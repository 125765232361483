package com.catbit.opinionpoll.ui.screens.home

import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.data.models.SimplifiedFormModel
import com.catbit.opinionpoll.ui.states.StatsUIState

sealed interface HomeUIStateContract {
    sealed interface State : HomeUIStateContract {
        data object Loading : State
        data object Failure : State
        data class Displaying(
            val greetings: String,
            val forms: Map<String, List<SimplifiedFormModel>>,
            val stats: StatsUIState?,
            val searchQuery: String = "",
            val selectedSorting: String = "Título",
            val sortingOptions: List<String> = listOf("Título", "Data de criação"),
            val selectedTabId: String = "polls",
            val tabItems: List<HomeTabItemUIState> = listOf(
                HomeTabItemUIState(
                    id = "polls",
                    text = "Pesquisas",
                    icon = MaterialIcons.Round.Poll
                ),
                HomeTabItemUIState(
                    id = "stats",
                    text = "Estatísticas",
                    icon = MaterialIcons.Round.PieChart
                )
            )
        ) : State
    }

    sealed interface Event : HomeUIStateContract {
        data class OnFormSearch(val query: String) : Event
        data class OnSortingChange(val sorting: String) : Event
        data object OnRequestMessage : Event
        data object OnClearSearch : Event
        data class OnTabSelected(val tabId: String): Event
    }

    sealed interface Effect : HomeUIStateContract {
        data object Initial : Effect
        data object OnDisplayMessageEffect : Effect
    }
}

data class HomeTabItemUIState(
    val id: String,
    val text: String,
    val icon: MaterialIcon
)