package com.catbit.opinionpoll.ui.screens.forms

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.getStateHolder
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.extensions.toBrazilianDatePattern
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.navigation_controller.argumentsBundleOf
import com.catbit.opinionpoll.core.sailor.overlays.dialog.DialogState
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.ui.composables.*
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.isMobileMode
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.res.Strings
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.flow.collectLatest
import org.jetbrains.compose.web.css.*

@Composable
fun FormsScreen(
    stackEntryId: String,
    navigationController: NavigationController,
    stateHolder: FormsStateHolder = getStateHolder(
        stackEntryId = stackEntryId
    )
) {
    val uiState = stateHolder.uiState.collectAsState().value
    val dialogState = LocalDialogStateProvider.current
    val coroutineScope = rememberCoroutineScope()
    val snackBarState = LocalSnackbarState.current

    SingleEffect {
        stateHolder.effects.collectLatest {
            when (it) {
                FormsUIContract.Effect.OnConfirmFormsDeletion -> {
                    dialogState.show {
                        Dialog(
                            modifier = Modifier.fillMaxWidth(),
                            title = "Apagar formulários?",
                            body = "Ao apagar um formulário, não será possível mais utilizá-lo em uma campanha. Respostas já enviadas serão mantidas.\n Deseja continuar?",
                            positiveText = "Sim",
                            onPositiveClick = {
                                stateHolder.onEvent(
                                    FormsUIContract.Event.OnConfirmFormsDeletion
                                )
                                dialogState.dismiss()
                            },
                            negativeText = "Não",
                            onNegativeClick = {
                                dialogState.dismiss()
                            }
                        )
                    }
                }

                FormsUIContract.Effect.OnStartFormsDeletion -> {
                    dialogState.show {
                        Column(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            CircularProgress(50)
                            Text(
                                modifier = Modifier.margin(top = 8.px),
                                text = "Apagando formulários"
                            )
                        }
                    }
                }

                FormsUIContract.Effect.OnFormsDeletionFailure -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope,
                        message = "Houve um erro ao apagar os formulários, tente novamente!",
                    )
                }

                FormsUIContract.Effect.OnFormsDeletionSuccess -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope,
                        message = "Formulários apagados com sucesso!"
                    )
                    stateHolder.onEvent(
                        FormsUIContract.Event.OnRefreshForms
                    )
                }

                FormsUIContract.Effect.OnStartFormCopy -> {
                    dialogState.show {
                        Column(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            CircularProgress(50)
                            Text(
                                modifier = Modifier.margin(top = 8.px),
                                text = "Copiando formulário"
                            )
                        }
                    }
                }

                FormsUIContract.Effect.OnFormCopySuccess -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope,
                        message = "Formulário copiado com sucesso!",
                    )
                }

                FormsUIContract.Effect.OnFormCopyFailure -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope,
                        message = "Houve um erro ao copiar o formulário, tente novamente!",
                    )
                }
            }
        }
    }

    Column(
        modifier = Modifier.fillMaxSize(),
    ) {

        var overflow by remember { mutableStateOf(Overflow.Hidden) }
        var showNavDrawer by remember { mutableStateOf(false) }

        when (uiState) {
            is FormsUIContract.State.Displaying -> {

                if (isMobileMode) {
                    Box(
                        modifier = Modifier.fillMaxSize()
                    ) {
                        if (uiState.forms.isEmpty()) {
                            Column(
                                modifier = Modifier.fillMaxSize()
                            ) {
                                Box(
                                    modifier = Modifier
                                        .margin(left = 24.px, top = 16.px)
                                        .minSize(40.px)
                                        .clip(Rect(40.px))
                                        .hoverBackground(
                                            onMouseEnterBackgroundColor = sitePalette().primary.changeAlpha(0.8f),
                                            onMouseExitBackgroundColor = sitePalette().primary
                                        )
                                        .cursor(Cursor.Pointer)
                                        .borderRadius(40.px)
                                        .onClick {
                                            showNavDrawer = true
                                        },
                                    contentAlignment = Alignment.Center
                                ) {
                                    MaterialIcon(
                                        icon = MaterialIcons.Round.Menu,
                                        tint = sitePalette().onPrimary
                                    )
                                }

                                Column(
                                    modifier = Modifier
                                        .padding(all = 24.px)
                                        .fillMaxSize(),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.Center
                                ) {
                                    MaterialIcon(
                                        icon = MaterialIcons.Round.Article,
                                        size = 48.px,
                                        tint = sitePalette().onSurface
                                    )
                                    Text(
                                        modifier = Modifier
                                            .margin(top = 16.px)
                                            .textAlign(TextAlign.Center)
                                            .color(sitePalette().onSurface)
                                            .titleLarge(),
                                        text = "Nenhum formulário cadastrado"
                                    )
                                    Text(
                                        modifier = Modifier
                                            .titleMedium()
                                            .textAlign(TextAlign.Center)
                                            .color(sitePalette().onSurface),
                                        text = "Você pode criar um formulário clicando ao lado em \"Adicionar formulário\""
                                    )
                                }
                            }
                        } else {
                            Column(
                                modifier = Modifier.fillMaxSize()
                            ) {
                                Box(
                                    modifier = Modifier
                                        .margin(left = 24.px, top = 16.px)
                                        .minSize(40.px)
                                        .clip(Rect(40.px))
                                        .hoverBackground(
                                            onMouseEnterBackgroundColor = sitePalette().primary.changeAlpha(0.8f),
                                            onMouseExitBackgroundColor = sitePalette().primary
                                        )
                                        .cursor(Cursor.Pointer)
                                        .borderRadius(40.px)
                                        .onClick {
                                            showNavDrawer = true
                                        },
                                    contentAlignment = Alignment.Center
                                ) {
                                    MaterialIcon(
                                        icon = MaterialIcons.Round.Menu,
                                        tint = sitePalette().onPrimary
                                    )
                                }

                                SearchBar(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(horizontal = 24.px)
                                        .margin(vertical = 16.px),
                                    sorting = SearchBarSorting(
                                        selected = uiState.searchSorting,
                                        options = uiState.searchSortingOptions,
                                        onSort = {
                                            stateHolder.onEvent(
                                                FormsUIContract.Event.OnFilterChange(it)
                                            )
                                        }
                                    ),
                                    query = uiState.searchQuery,
                                    compactMode = true,
                                    onClear = {
                                        stateHolder.onEvent(
                                            FormsUIContract.Event.OnClearSearchQuery
                                        )
                                    }
                                ) {
                                    stateHolder.onEvent(
                                        FormsUIContract.Event.OnSearchQueryChange(it)
                                    )
                                }

                                if (uiState.isOnFormDeletionMode) {
                                    Row(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .padding(horizontal = 24.px)
                                            .display(DisplayStyle.Flex)
                                            .flexWrap(FlexWrap.Wrap)
                                            .margin(bottom = 16.px),
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        ToolbarButton(
                                            text = "Apagar",
                                            enabled = uiState.enableDeleteFormsButton,
                                            icon = MaterialIcons.Round.DeleteOutline
                                        ) {
                                            stateHolder.onEvent(FormsUIContract.Event.OnDeleteFormClick)
                                        }
                                        ToolbarButton(
                                            text = "Marcar todos",
                                            enabled = true,
                                            icon = MaterialIcons.Round.SelectAll
                                        ) {
                                            stateHolder.onEvent(FormsUIContract.Event.OnSelectAllFormsForDeletion)
                                        }
                                        ToolbarButton(
                                            text = "Cancelar",
                                            enabled = true,
                                            icon = MaterialIcons.Round.Close
                                        ) {
                                            stateHolder.onEvent(FormsUIContract.Event.OnCancelDeletingForms)
                                        }
                                    }
                                }

                                Column(
                                    modifier = Modifier
                                        .padding(left = 24.px, right = 24.px, bottom = 16.px)
                                        .overflow {
                                            y(Overflow.Auto)
                                            x(Overflow.Hidden)
                                        }
                                        .display(DisplayStyle.Flex)
                                        .gap(16.px)
                                        .fillMaxWidth()
                                ) {
                                    uiState.forms
                                        .filter { it.title.contains(uiState.searchQuery, ignoreCase = true) }
                                        .sortedWith { form1, form2 ->
                                            when (uiState.searchSorting) {
                                                "Data de criação" -> if (form1.creationDate < form2.creationDate) {
                                                    1
                                                } else if (form1.creationDate > form2.creationDate) {
                                                    -1
                                                } else 0

                                                else -> form1.title.compareTo(form2.title)
                                            }
                                        }
                                        .forEach { form ->
                                            Row(
                                                modifier = Modifier.fillMaxWidth(),
                                                verticalAlignment = Alignment.CenterVertically
                                            ) {
                                                if (uiState.isOnFormDeletionMode) {
                                                    Checkbox(
                                                        modifier = Modifier.margin(right = 16.px),
                                                        checked = form.isSelectedForDeletion,
                                                        onCheck = {
                                                            stateHolder.onEvent(
                                                                FormsUIContract.Event.OnFormSelectedForDeletion(
                                                                    form.identifier
                                                                )
                                                            )
                                                        }
                                                    )
                                                }

                                                CardWithIcon(
                                                    modifier = Modifier.width(
                                                        if (uiState.isOnFormDeletionMode)
                                                            100.percent.minus(40.px) else 100.percent
                                                    ),
                                                    icon = MaterialIcons.Round.Poll,
                                                    alignment = CardWithIconAlignment.Top
                                                ) {
                                                    Column(
                                                        modifier = Modifier
                                                            .overflow { x(Overflow.Hidden) }
                                                            .fillMaxWidth(),
                                                    ) {
                                                        Text(
                                                            modifier = Modifier
                                                                .color(sitePalette().onSurface)
                                                                .overflow { x(Overflow.Hidden) }
                                                                .whiteSpace(WhiteSpace.NoWrap)
                                                                .fillMaxWidth()
                                                                .textOverflow(TextOverflow.Ellipsis)
                                                                .titleMedium(),
                                                            text = form.title
                                                        )
                                                        Text(
                                                            modifier = Modifier
                                                                .color(
                                                                    sitePalette().onSurface.changeAlpha(
                                                                        0.8f
                                                                    )
                                                                )
                                                                .titleSmall()
                                                                .noWrap(),
                                                            text = "Criado em ${form.creationDate.toBrazilianDatePattern()}"
                                                        )
                                                        Text(
                                                            modifier = Modifier
                                                                .color(
                                                                    sitePalette().onSurface.changeAlpha(
                                                                        0.8f
                                                                    )
                                                                )
                                                                .titleSmall()
                                                                .noWrap(),
                                                            text = "Expira em ${form.expirationDate.toBrazilianDatePattern()}"
                                                        )

                                                        Row(
                                                            modifier = Modifier
                                                                .fillMaxWidth()
                                                                .margin(top = 8.px),
                                                            horizontalArrangement = Arrangement.End
                                                        ) {
                                                            if (!uiState.isOnFormDeletionMode) {
                                                                MaterialIcon(
                                                                    modifier = Modifier
                                                                        .padding(all = 8.px)
                                                                        .margin(right = 8.px)
                                                                        .clip(Circle())
                                                                        .cursor(Cursor.Pointer)
                                                                        .hoverBackground(
                                                                            onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                                0.8f
                                                                            ),
                                                                            onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                                        )
                                                                        .onClick {
                                                                            prepareForCopy(
                                                                                targetFormId = form.identifier,
                                                                                targetFormTitle = form.title,
                                                                                allFormsTitles = uiState.forms.map { it.title },
                                                                                dialogState = dialogState,
                                                                                onEvent = { stateHolder.onEvent(it) }
                                                                            )
                                                                        },
                                                                    icon = MaterialIcons.Round.ContentCopy
                                                                )
                                                                Tooltip(
                                                                    target = ElementTarget.PreviousSibling,
                                                                    text = "Fazer cópia",
                                                                    placement = PopupPlacement.Left
                                                                )
                                                                MaterialIcon(
                                                                    modifier = Modifier
                                                                        .padding(all = 8.px)
                                                                        .margin(right = 8.px)
                                                                        .clip(Circle())
                                                                        .cursor(Cursor.Pointer)
                                                                        .hoverBackground(
                                                                            onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                                0.8f
                                                                            ),
                                                                            onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                                        )
                                                                        .onClick {
                                                                            navigationController.navigate(
                                                                                routeName = "form_maker",
                                                                                arguments = argumentsBundleOf(
                                                                                    "formIdentifier" to form.identifier,
                                                                                    "formTitle" to form.title
                                                                                )
                                                                            )
                                                                        },
                                                                    icon = MaterialIcons.Round.Edit
                                                                )
                                                                Tooltip(
                                                                    target = ElementTarget.PreviousSibling,
                                                                    text = "Editar",
                                                                    placement = PopupPlacement.Left
                                                                )
                                                                MaterialIcon(
                                                                    modifier = Modifier
                                                                        .padding(all = 8.px)
                                                                        .margin(right = 8.px)
                                                                        .clip(Circle())
                                                                        .cursor(Cursor.Pointer)
                                                                        .hoverBackground(
                                                                            onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                                0.8f
                                                                            ),
                                                                            onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                                        )
                                                                        .onClick {
                                                                            navigationController.navigate(
                                                                                routeName = "form_users_linker",
                                                                                arguments = argumentsBundleOf(
                                                                                    "formIdentifier" to form.identifier,
                                                                                    "formTitle" to form.title
                                                                                )
                                                                            )
                                                                        },
                                                                    icon = MaterialIcons.Round.Link
                                                                )
                                                                Tooltip(
                                                                    target = ElementTarget.PreviousSibling,
                                                                    text = "Vincular à membros da equipe",
                                                                    placement = PopupPlacement.Left
                                                                )
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                }
                            }
                        }

                        if (showNavDrawer) {
                            Row(
                                modifier = Modifier
                                    .zIndex(90)
                                    .backgroundColor(sitePalette().background.darkened().changeAlpha(0.8f))
                                    .fillMaxSize()
                            ) {
                                NavDrawer(
                                    selectedMenu = uiState.selectedMenu,
                                    onEvent = { event -> stateHolder.onEvent(event) },
                                    navigationController = navigationController,
                                    navDrawerWidth = 100.percent.minus(80.px),
                                    onItemClick = { showNavDrawer = false }
                                )

                                Box(
                                    modifier = Modifier
                                        .size(40.px)
                                        .margin(top = 16.px, left = 16.px, right = 24.px)
                                        .clip(Rect(40.px))
                                        .hoverBackground(
                                            onMouseEnterBackgroundColor = sitePalette().primary.changeAlpha(0.8f),
                                            onMouseExitBackgroundColor = sitePalette().primary
                                        )
                                        .cursor(Cursor.Pointer)
                                        .borderRadius(40.px)
                                        .onClick {
                                            showNavDrawer = false
                                        },
                                    contentAlignment = Alignment.Center
                                ) {
                                    MaterialIcon(
                                        icon = MaterialIcons.Round.Menu,
                                        tint = sitePalette().onPrimary
                                    )
                                }
                            }
                        }
                    }
                } else {
                    Row(
                        modifier = Modifier.fillMaxSize()
                    ) {
                        NavDrawer(
                            selectedMenu = uiState.selectedMenu,
                            onEvent = { event -> stateHolder.onEvent(event) },
                            navigationController = navigationController
                        )

                        if (uiState.forms.isEmpty()) {
                            Column(
                                modifier = Modifier
                                    .padding(top = 24.px, right = 24.px)
                                    .fillMaxSize(),
                                horizontalAlignment = Alignment.CenterHorizontally,
                                verticalArrangement = Arrangement.Center
                            ) {
                                MaterialIcon(
                                    icon = MaterialIcons.Round.Article,
                                    size = 48.px,
                                    tint = sitePalette().onSurface
                                )
                                Text(
                                    modifier = Modifier
                                        .margin(top = 16.px)
                                        .textAlign(TextAlign.Center)
                                        .color(sitePalette().onSurface)
                                        .titleLarge(),
                                    text = "Nenhum formulário cadastrado"
                                )
                                Text(
                                    modifier = Modifier
                                        .titleMedium()
                                        .textAlign(TextAlign.Center)
                                        .color(sitePalette().onSurface),
                                    text = "Você pode criar um formulário clicando ao lado em \"Adicionar formulário\""
                                )
                            }
                        } else {
                            Column(
                                modifier = Modifier
                                    .padding(top = 24.px, right = 24.px)
                                    .fillMaxSize()
                            ) {
                                SearchBar(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(left = 24.px)
                                        .margin(bottom = 16.px),
                                    sorting = SearchBarSorting(
                                        selected = uiState.searchSorting,
                                        options = uiState.searchSortingOptions,
                                        onSort = {
                                            stateHolder.onEvent(
                                                FormsUIContract.Event.OnFilterChange(it)
                                            )
                                        }
                                    ),
                                    hint = "Pesquise por títulos de formulários",
                                    query = uiState.searchQuery,
                                    onClear = {
                                        stateHolder.onEvent(
                                            FormsUIContract.Event.OnClearSearchQuery
                                        )
                                    }
                                ) {
                                    stateHolder.onEvent(
                                        FormsUIContract.Event.OnSearchQueryChange(it)
                                    )
                                }

                                if (uiState.isOnFormDeletionMode) {
                                    Row(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .padding(horizontal = 24.px)
                                            .margin(bottom = 16.px),
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Text(
                                            modifier = Modifier
                                                .color(sitePalette().primary)
                                                .titleMedium()
                                                .noWrap(),
                                            text = "Apagar formulários"
                                        )
                                        Spacer(Modifier.fillMaxWidth())
                                        ToolbarButton(
                                            text = "Apagar",
                                            enabled = uiState.enableDeleteFormsButton,
                                            icon = MaterialIcons.Round.DeleteOutline
                                        ) {
                                            stateHolder.onEvent(FormsUIContract.Event.OnDeleteFormClick)
                                        }
                                        ToolbarButton(
                                            text = "Marcar todos",
                                            enabled = true,
                                            icon = MaterialIcons.Round.SelectAll
                                        ) {
                                            stateHolder.onEvent(FormsUIContract.Event.OnSelectAllFormsForDeletion)
                                        }
                                        ToolbarButton(
                                            text = "Cancelar",
                                            enabled = true,
                                            icon = MaterialIcons.Round.Close
                                        ) {
                                            stateHolder.onEvent(FormsUIContract.Event.OnCancelDeletingForms)
                                        }
                                    }
                                }

                                Column(
                                    modifier = Modifier
                                        .padding(bottom = 24.px, left = 24.px)
                                        .overflow { y(overflow) }
                                        .onMouseEnter { overflow = Overflow.Auto }
                                        .onMouseLeave { overflow = Overflow.Hidden }
                                        .display(DisplayStyle.Flex)
                                        .gap(16.px)
                                        .fillMaxWidth()
                                ) {
                                    uiState.forms
                                        .filter { it.title.contains(uiState.searchQuery, ignoreCase = true) }
                                        .sortedWith { form1, form2 ->
                                            when (uiState.searchSorting) {
                                                "Data de criação" -> if (form1.creationDate < form2.creationDate) {
                                                    1
                                                } else if (form1.creationDate > form2.creationDate) {
                                                    -1
                                                } else 0

                                                else -> form1.title.compareTo(form2.title)
                                            }
                                        }
                                        .forEach { form ->
                                            Row(
                                                modifier = Modifier.fillMaxWidth(),
                                                verticalAlignment = Alignment.CenterVertically
                                            ) {
                                                if (uiState.isOnFormDeletionMode) {
                                                    Checkbox(
                                                        modifier = Modifier.margin(right = 16.px),
                                                        checked = form.isSelectedForDeletion,
                                                        onCheck = {
                                                            stateHolder.onEvent(
                                                                FormsUIContract.Event.OnFormSelectedForDeletion(
                                                                    form.identifier
                                                                )
                                                            )
                                                        }
                                                    )
                                                }
                                                CardWithIcon(
                                                    modifier = Modifier.fillMaxWidth(),
                                                    icon = MaterialIcons.Round.Poll
                                                ) {
                                                    Row(
                                                        modifier = Modifier.fillMaxWidth(),
                                                        verticalAlignment = Alignment.Bottom
                                                    ) {
                                                        Column {
                                                            Text(
                                                                modifier = Modifier
                                                                    .color(sitePalette().onSurface)
                                                                    .titleMedium()
                                                                    .noWrap(),
                                                                text = form.title
                                                            )
                                                            Text(
                                                                modifier = Modifier
                                                                    .color(
                                                                        sitePalette().onSurface.changeAlpha(
                                                                            0.8f
                                                                        )
                                                                    )
                                                                    .titleSmall()
                                                                    .noWrap(),
                                                                text = "Criado em ${form.creationDate.toBrazilianDatePattern()}"
                                                            )
                                                            Text(
                                                                modifier = Modifier
                                                                    .color(
                                                                        sitePalette().onSurface.changeAlpha(
                                                                            0.8f
                                                                        )
                                                                    )
                                                                    .titleSmall()
                                                                    .noWrap(),
                                                                text = "Expira em ${form.expirationDate.toBrazilianDatePattern()}"
                                                            )
                                                        }
                                                        if (!uiState.isOnFormDeletionMode) {
                                                            Spacer(modifier = Modifier.fillMaxWidth())
                                                            MaterialIcon(
                                                                modifier = Modifier
                                                                    .padding(all = 8.px)
                                                                    .margin(right = 8.px)
                                                                    .clip(Circle())
                                                                    .cursor(Cursor.Pointer)
                                                                    .hoverBackground(
                                                                        onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                            0.8f
                                                                        ),
                                                                        onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                                    )
                                                                    .onClick {
                                                                        prepareForCopy(
                                                                            targetFormId = form.identifier,
                                                                            targetFormTitle = form.title,
                                                                            allFormsTitles = uiState.forms.map { it.title },
                                                                            dialogState = dialogState,
                                                                            onEvent = { stateHolder.onEvent(it) }
                                                                        )
                                                                    },
                                                                icon = MaterialIcons.Round.ContentCopy
                                                            )
                                                            Tooltip(
                                                                target = ElementTarget.PreviousSibling,
                                                                text = "Fazer cópia",
                                                                placement = PopupPlacement.Left
                                                            )
                                                            MaterialIcon(
                                                                modifier = Modifier
                                                                    .padding(all = 8.px)
                                                                    .margin(right = 8.px)
                                                                    .clip(Circle())
                                                                    .cursor(Cursor.Pointer)
                                                                    .hoverBackground(
                                                                        onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                            0.8f
                                                                        ),
                                                                        onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                                    )
                                                                    .onClick {
                                                                        navigationController.navigate(
                                                                            routeName = "form_maker",
                                                                            arguments = argumentsBundleOf(
                                                                                "formIdentifier" to form.identifier,
                                                                                "formTitle" to form.title
                                                                            )
                                                                        )
                                                                    },
                                                                icon = MaterialIcons.Round.Edit
                                                            )
                                                            Tooltip(
                                                                target = ElementTarget.PreviousSibling,
                                                                text = "Editar",
                                                                placement = PopupPlacement.Left
                                                            )
                                                            MaterialIcon(
                                                                modifier = Modifier
                                                                    .padding(all = 8.px)
                                                                    .margin(right = 8.px)
                                                                    .clip(Circle())
                                                                    .cursor(Cursor.Pointer)
                                                                    .hoverBackground(
                                                                        onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                            0.8f
                                                                        ),
                                                                        onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                                    )
                                                                    .onClick {
                                                                        navigationController.navigate(
                                                                            routeName = "form_users_linker",
                                                                            arguments = argumentsBundleOf(
                                                                                "formIdentifier" to form.identifier,
                                                                                "formTitle" to form.title
                                                                            )
                                                                        )
                                                                    },
                                                                icon = MaterialIcons.Round.Link
                                                            )
                                                            Tooltip(
                                                                target = ElementTarget.PreviousSibling,
                                                                text = "Vincular à membros da equipe",
                                                                placement = PopupPlacement.Left
                                                            )
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                }
                            }
                        }
                    }
                }
            }

            is FormsUIContract.State.Failure -> {
                Text("Falha")
            }

            is FormsUIContract.State.Loading -> {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    CircularProgress(50)
                    Text("Carregando")
                }
            }
        }
    }
}

private fun prepareForCopy(
    targetFormId: String,
    targetFormTitle: String,
    allFormsTitles: List<String>,
    dialogState: DialogState,
    onEvent: (FormsUIContract.Event) -> Unit
) {
    dialogState.show {
        var enableCopyButton by remember { mutableStateOf(true) }
        var newTitle by remember { mutableStateOf("Cópia de $targetFormTitle") }

        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            Text(
                text = "Qual será o nome do novo formulário?",
                modifier = Modifier
                    .fillMaxWidth()
                    .headlineSmall()
            )
            Text(
                text = "Defina um nome diferente do formulário base",
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth()
                    .bodyMedium()
            )

            TextField(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth(),
                text = newTitle,
                onTextChanged = {
                    newTitle = it
                    enableCopyButton = newTitle != targetFormTitle && newTitle !in allFormsTitles
                }
            )

            Row(
                modifier = Modifier
                    .margin(top = 24.px)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.End
            ) {
                HoverableContent(
                    modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                    onHoverEndColor = sitePalette().surface,
                    onClick = { dialogState.dismiss() }
                ) {
                    Text(
                        text = Strings.cancel,
                        modifier = Modifier.bodyLarge()
                    )
                }
                HoverableContent(
                    modifier = Modifier
                        .padding(horizontal = 16.px, vertical = 8.px),
                    onHoverStartColor = if (enableCopyButton)
                        sitePalette().secondaryContainer.changeAlpha(0.8f)
                    else
                        sitePalette().surface,
                    onHoverEndColor = sitePalette().surface,
                    cursor = if (enableCopyButton) Cursor.Pointer else Cursor.NotAllowed,
                    onClick = {
                        if (enableCopyButton) {
                            onEvent(
                                FormsUIContract.Event.OnCopyFormClick(
                                    formId = targetFormId,
                                    newTitle = newTitle
                                )
                            )
                            dialogState.dismiss()
                        }
                    }
                ) {
                    Text(
                        text = Strings.copy,
                        modifier = Modifier
                            .color(
                                if (enableCopyButton)
                                    sitePalette().onSurface
                                else
                                    sitePalette().onSurface.changeAlpha(0.5f)
                            )
                            .bodyLarge()
                    )
                }
            }
        }
    }
}

@Composable
private fun NavDrawer(
    selectedMenu: String,
    onEvent: (FormsUIContract.Event) -> Unit,
    navigationController: NavigationController,
    navDrawerWidth: CSSNumeric = 360.px,
    onItemClick: (String) -> Unit = {}
) {
    Column(
        modifier = Modifier
            .background(sitePalette().surfaceVariant)
            .padding(horizontal = 12.px, vertical = 24.px)
            .roundedCornerClip(
                topRight = 16.px,
                bottomRight = 16.px
            )
            .fillMaxHeight()
            .width(navDrawerWidth)
    ) {
        NavDrawerButton(
            icon = MaterialIcons.Round.AddCircleOutline,
            label = "Adicionar formulário",
            selected = selectedMenu == "add_form",
            onClick = {
                onItemClick("form_maker")
                navigationController.navigate("form_maker")
            }
        )
        NavDrawerButton(
            icon = MaterialIcons.Round.DeleteOutline,
            label = "Apagar formulários",
            selected = selectedMenu == "delete_forms",
            onClick = {
                onItemClick("delete_forms")
                if (selectedMenu != "delete_forms") {
                    onEvent(FormsUIContract.Event.OnMenuDeleteFormClick)
                }
            }
        )
    }
}