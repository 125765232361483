package com.catbit.opinionpoll.ui.screens.home

import com.catbit.opinionpoll.core.domain.invoke
import com.catbit.opinionpoll.core.extensions.kotlinLocalDateTime
import com.catbit.opinionpoll.core.extensions.updateAs
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.data.models.SimplifiedFormModel
import com.catbit.opinionpoll.data.models.StatsModel
import com.catbit.opinionpoll.domain.home.GetHomeUseCase
import com.catbit.opinionpoll.domain.home.GetStatsUseCase
import com.catbit.opinionpoll.inputs.state.InputGraphUIState
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import com.catbit.opinionpoll.ui.screens.home.HomeUIStateContract.*
import com.catbit.opinionpoll.ui.states.StatsUIState
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.datetime.internal.JSJoda.LocalDateTime
import kotlinx.datetime.internal.JSJoda.ZoneId

class HomeStateHolder(
    private val getHomeUseCase: GetHomeUseCase,
    private val getStatsUseCase: GetStatsUseCase
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(State.Loading)

    override fun onStarted() {
        stateHolderScope.launch {
            getHomeUseCase()
                .onSuccess { homeModel ->

                    val currentDateTime = LocalDateTime.now(ZoneId.SYSTEM).kotlinLocalDateTime()

                    internalUIState.update {
                        with(homeModel) {
                            State.Displaying(
                                greetings = greetings,
                                stats = null,
                                forms = mutableMapOf<String, List<SimplifiedFormModel>>().apply {
                                    val partedForms = forms.partition { form ->
                                        form.expirationDate > currentDateTime
                                    }
                                    this["Pesquisas ativas"] = partedForms.first.sortedByDescending { it.creationDate }
                                    this["Pesquisas expiradas"] =
                                        partedForms.second.sortedByDescending { it.creationDate }
                                }
                            )
                        }
                    }
                }
                .onFailure {
                    internalUIState.update { State.Failure }
                }

            getStatsUseCase()
                .catch {
                    internalUIState.update { State.Failure }
                }
                .collectLatest { stats ->
                    internalUIState.updateAs<State.Displaying> {
                        copy(
                            stats = StatsUIState(
                                totalAnswersByFormChart = getTotalAnswersByFormChart(
                                    forms = forms.values.flatten(),
                                    stats = stats,
                                ),
                                totalAnswers = stats.formsTotalAnswers.values.fold(0) { acc, total -> acc + total },
                                totalForms = stats.totalForms,
                                totalUsers = stats.totalUsers,
                            )
                        )
                    }
                }
        }
    }

    private fun getTotalAnswersByFormChart(
        forms: List<SimplifiedFormModel>,
        stats: StatsModel
    ): InputGraphUIState {

        val formsIdsMaps = forms.associateBy { it.identifier }

        return InputGraphUIState(
            identifier = UUID.stringUUID(),
            title = "Total de respostas por formulário",
            data = stats.formsTotalAnswers.map { (formId, totalAnswers) ->
                InputGraphUIState.ChartData(
                    label = formsIdsMaps.getValue(formId).title,
                    value = totalAnswers
                )
            },
            chartType = InputGraphUIState.CharType.Bar
        )
    }

    override fun onEvent(event: Event) {
        when (event) {
            Event.OnRequestMessage -> {
                stateHolderScope.launch {
                    internalEffects.emit(Effect.OnDisplayMessageEffect)
                }
            }

            Event.OnClearSearch -> onClearSearch()
            is Event.OnFormSearch -> onFormSearch(event.query)
            is Event.OnSortingChange -> onSortingChange(event.sorting)
            is Event.OnTabSelected -> onTabSelected(event.tabId)
        }
    }

    private fun onTabSelected(tabId: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(selectedTabId = tabId)
        }
    }

    private fun onSortingChange(sorting: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(selectedSorting = sorting)
        }
    }

    private fun onFormSearch(query: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(searchQuery = query)
        }
    }

    private fun onClearSearch() {
        internalUIState.updateAs<State.Displaying> {
            copy(searchQuery = "")
        }
    }
}